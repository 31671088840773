<template>
  <div class="wrapper">

<!--     订单列表-->
<!--    <headBack>-->
<!--      <template v-slot:title>-->
<!--        <div class='title'>-->
<!--             我的报名-->
<!--        </div>-->
<!--      </template>-->
<!--    </headBack>-->
    <div class="back_cont" @click="goToPage">
      <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"' />
       我的报名
    </div>
    <div class='header_cont'></div>
    <div class="list">
      <div class="no_b" v-if="!list">
        <img src="https://static.yihu365.cn/img/h5Img/shop/no_order.png" alt="">
        <div>您还没有报名噢~</div>
      </div>
             <div class="item" v-for="(item,index) in list" :key="index" @click="toDetail(item)">
                  <div class="name">
                      <span class="tit">{{item.PRODUCT_NAME}}</span>
                      <span class="sta">{{setStates(item)}}</span>
                  </div>
                  <div class="sub">
                    <img src="https://static.yihu365.cn/img/h5Img/user.png" alt="">
                    <span>{{item.USER_NAME}}</span>
                  </div>
               <div class="sub">
                 <img src="https://static.yihu365.cn/img/h5Img/money.png" alt="">
                 <span>服务费{{item.PRICE}}元</span>
               </div>
               <div class="sub">
                 <img src="https://static.yihu365.cn/img/h5Img/time.png" alt="">
                 <span>{{item.CREATE_DATE}}</span>
               </div>
             </div>

    </div>
  </div>
</template>

<script>
import {reactive,ref} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {Toast} from "vant";
export default {
  name: "orderList",
  setup(){

    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const list = ref('')
    const getList =()=> {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityOrderList",
        userId: store.state.uid,
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {
          console.log(res)
        list.value = res.data;

      })
    }
    getList();

    // state
    const states = ref('');

    const setStates = (detail) => {
      if(detail.ORDERSTATUS == -1){
        states.value = '超时取消'
        return '超时取消'
      }
      if(detail.ORDERSTATUS == 0 && detail.PAYSTATUS ==0){
        states.value = '待付款'
        return '待付款'
      }
      if(detail.ORDERSTATUS == 0 && detail.PAYSTATUS ==1){
        states.value = '已完成'
        return '已完成'
      }
      if(detail.ORDERSTATUS == 2 && detail.PAYSTATUS ==0){
        states.value = '已取消'
        return '已取消'
      }
      if(detail.ORDERSTATUS == 3 ){
        states.value = '退款中'
        return '退款中'
      }
      if(detail.ORDERSTATUS == 7 && detail.PAYSTATUS ==2){
        states.value = '已退款'
        return '已退款'
      }

    }
    const toDetail =(item)=> {
        router.push({
           name:'communityOrderDetail',
           query:{
             orderId:item.ORDER_ID
           }
        })
    }
    const goToPage = ()=> {
      router.push({
        name:'communityList',
      })
    }
    return {
      list,
      toDetail,
      setStates,
      goToPage
    }
  }
}
</script>

<style scoped lang="scss">
   .wrapper{
       background-color: #f2f4f5;
     min-height: 100vh;
     padding-bottom: 40px;
   }
   .list{
     .no_b{
       text-align: center;
       font-size: 28px;
       color:#999;
       img{
         width: 50%;
       }
     }
     box-sizing: border-box;

     .item{
       color:#323232;
       width: 94%;
       margin:20px auto;
       background: #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding:20px 24px;
       font-size: 30px;
       .name{
         display: flex;
         justify-content: space-between;
         border-bottom: 1px solid #f3f3f3;
         padding-bottom: 12px;
         .tit{

         }
         .sta{
           color:#999999;
           font-size: 26px;
         }
       }
       .sub{
         display: flex;
         align-items: center;
         color:#666666;
         height:50px;
         font-size: 28px;
         img{
           width: 36px;
           margin-right: 10px;
         }
       }
     }
   }

   .back_cont{
     width: 100%;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     //justify-content: space-between;
     height: 88px;
     line-height: 88px;
     border-bottom: 1px solid #F5F5F5;
     box-sizing: border-box;
     font-size: 34px;
     font-family: PingFangSC-Medium, PingFang SC;
     font-weight: 500;
     color: #313131;
     background: #fff;
     z-index: 9999;
     .icon_box{
       margin-left: 10px;
       box-sizing: border-box;
       padding-top: 12px;
     }
     .backicon{
       position: absolute;
       left: 15px;
       width: 20px;

     }
   }
</style>
